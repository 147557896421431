<template>
  <div
    v-if="post.name"
  >
    <div
      class="bg-gradient-to-br from-gray-50 to-gray-100 dark:to-black dark:from-gray-800 overflow-hidden"
    >
      <cgn-breadcrumb :links="breadcrumbs" />
      <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 mt-8">
        <div
          class="hidden lg:block bg-gray-100 dark:bg-gray-900 absolute top-0 bottom-0 left-3/4 w-screen"
        />
        <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h1
              class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl"
            >
              {{ post.name }}
            </h1>
          </div>
        </div>
        <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div class="relative lg:row-start-1 lg:col-start-2">
            <svg
              class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200 dark:text-gray-800"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <cgn-lazy-image
                    class="rounded-lg shadow-lg object-cover object-center"
                    :image="post.image"
                    width="1184"
                  />
                </div>
              <!--
              <figcaption class="mt-3 flex text-sm text-gray-500">
                <span class="ml-2">Photograph by Marcus O’Leary</span>
              </figcaption>
              -->
              </figure>
            </div>
          </div>
          <div class="mt-8 lg:mt-0">
            <div
              class="mt-5 prose prose-brand dark:prose-dark text-gray-500 dark:text-gray-200 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1"
              v-html="post.content"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoArticle } from '~cognito/models/Cognito/Article'

class Templatevars {
}

const image_width = 1184</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  item: {
    type: String,
    required: true,
  },
})
const post = ref<CognitoArticle>(new CognitoArticle())

useSchemaOrg([
  defineArticle({
    // name and description can usually be inferred
    image: computed(() => post.value?.image?.url),
    // datePublished: new Date(2020, 1, 1),
    // dateModified: new Date(2020, 1, 1),
  }),
])



const breadcrumbs = ref([{ name: 'Blog', url: '/blog' }])

const loadArticle = async (url: string) => {
  post.value.name = ''
  breadcrumbs.value = [{ name: 'Blog', url: '/blog' }]
  const data = await new CognitoArticle().find_one({
    url,
    image_aspect: 'Square',
    image_width,
  })
  post.value = data
  if (post.value.groups?.length > 0) {
    breadcrumbs.value.push({ name: post.value.groups[0].name, url: `/blog/${post.value.groups[0].slug}` })
  }
  breadcrumbs.value.push({ name: post.value.name, url })
}

watch(() => props.item, (newUrl) => {
  loadArticle(newUrl)
})
onMounted(() => {
  loadArticle(props.item)
})
onServerPrefetch(async () => {
  await loadArticle(props.item)
})
</script>
