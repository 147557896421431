<template>
  <div class="p-6 max-w-5xl mx-auto">
    <div class="pt-10 lg:flex lg:items-center lg:justify-between">
      <div>
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          <span class="block sm:inline-block">Welcome to</span>
          <span class="text-brand-600 dark:text-brand-500 sm:pl-2">RealConnect</span>
        </h2>
      </div>
    </div>
    <new-lead-start class="mt-5" />
  </div>
  <cgn-page-builder-router page="home" class="mt-20" />
</template>

<script setup lang="ts">

</script>
