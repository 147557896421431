<template>
  <div class="p-6 sm:p-12 space-y-2 text-gray-900 dark:text-gray-200 mx-auto max-w-5xl lg:row-start-1 lg:col-start-1">
    <h1 class="text-2xl font-semibold text-center">
      {{ templatevar.heading }}
    </h1>
    <div class="prose prose-brand dark:prose-dark max-w-none" v-html="templatevar.block_content" />
  </div>
  <hr
    v-if="templatevar.show_rule_at_bottom"
    class="border-gray-300 dark:border-gray-700 border-2"
  >
</template>

<script lang="ts">
class Templatevars {
  heading?: string
  block_content?: string
  show_rule_at_bottom?: boolean
  background_style?: string
  background_image?: number
  padding_top?: number
  padding_bottom?: number
  margin_top?: number
  margin_bottom?: number
  submit_property_button_name?: string
  anchor_name?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
