<template>
  <div v-if="widget.outer == 'tpl/heading_and_content'">
    <page-builder-heading-and-content :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/article_list'">
    <page-builder-article-list :templatevar="widget.templatevar" :group="urlParts.item_url" :page="urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'tpl/article_view'">
    <page-builder-article-view :templatevar="widget.templatevar" :item="urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'tpl/home'">
    <page-builder-home :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Real/sell-property'">
    <page-builder-sell-property :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Real/servicefee'">
    <page-builder-service-fee :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/how_it_works'">
    <page-builder-how-it-works :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/how_it_works_vertical'">
    <page-builder-how-it-works-vertical :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/faq'">
    <page-builder-faq :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/hero_compare'">
    <page-builder-hero-compare :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/compareblocks'">
    <page-builder-compare-blocks :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/three_info_cards'">
    <page-builder-three-info-cards :templatevar="widget.templatevar" />
  </div>
  <cgn-page-builder v-else :widget="widget" :url-parts="urlParts" :contained-class="props.containedClass" />
</template>

<script setup lang="ts">
import { CognitoUrlParts } from '~cognito/models/Cognito/Page'

const props = defineProps({
  widget: {
    required: true,
  },
  containedClass: {
    type: String,
    default: 'px-6 py-2 mx-auto max-w-5xl',
  },
  urlParts: {
    type: CognitoUrlParts,
    required: true,
  },
})
</script>
