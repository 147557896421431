<template>
  <cgn-router-view />
</template>

<script setup lang="ts">
useSchemaOrg([
  // @todo Select Identity: https://unhead-schema-org.harlanzw.com//guide/guides/identity
  defineOrganization({
    name: 'Real-Connect Pty Ltd',
    logo: () => usePagesStore().currentDomain.favicon.url,
  }),
  defineWebSite({
    name: 'Real-Connect Australia',
  }),
  defineWebPage(),
])

useHead({
  link: [
    {
      rel: 'preconnect',
      href: 'https://rcnn.b-cdn.net',
    },
  ],
})
</script>
