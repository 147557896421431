<template>
  <div class="p-6 sm:p-12 space-y-2 text-gray-700 dark:text-gray-200 mx-auto max-w-5xl">
    <div class="text-center grid md:grid-cols-3 gap-4">
      <div v-for="block in blocks" :key="block.heading" class="flex flex-col justify-between bg-white dark:bg-slate-800 rounded-md overflow-hidden">
        <div>
          <div class="w-full text-white py-4" :style="{ 'background-color': block.colour }">
            <cgn-lazy-image
              :image-id="block.image" image-aspect="1x1" image-width="150" class="mb-2 rounded-full inline"
              force-size
            />
            <h4 class="text-2xl">
              {{ block.heading }}
            </h4>
            <span class="text-xl">
              {{ block.subheading }}
            </span>
          </div>
          <div class="prose dark:prose-invert p-2" v-html="block.content" />
        </div>

        <ss-button class="p-2" :url="block.url">
          {{ block.button }}
        </ss-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
class Templatevars {
  block_1_heading?: string
  block_1_subheading?: string
  block_1_content?: string
  block_1_button?: string
  block_1_link_url?: string
  block_1_colour?: string
  block_1_image?: string

  block_2_heading?: string
  block_2_subheading?: string
  block_2_content?: string
  block_2_button?: string
  block_2_link_url?: string
  block_2_colour?: string
  block_2_image?: string

  block_3_heading?: string
  block_3_subheading?: string
  block_3_content?: string
  block_3_button?: string
  block_3_link_url?: string
  block_3_colour?: string
  block_3_image?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const blocks = computed(() => {
  return [
    {
      heading: props.templatevar.block_1_heading,
      subheading: props.templatevar.block_1_subheading,
      content: props.templatevar.block_1_content,
      button: props.templatevar.block_1_button,
      url: props.templatevar.block_1_link_url,
      colour: props.templatevar.block_1_colour,
      image: props.templatevar.block_1_image,
    },
    {
      heading: props.templatevar.block_2_heading,
      subheading: props.templatevar.block_2_subheading,
      content: props.templatevar.block_2_content,
      button: props.templatevar.block_2_button,
      url: props.templatevar.block_2_link_url,
      colour: props.templatevar.block_2_colour,
      image: props.templatevar.block_2_image,
    },
    {
      heading: props.templatevar.block_3_heading,
      subheading: props.templatevar.block_3_subheading,
      content: props.templatevar.block_3_content,
      button: props.templatevar.block_3_button,
      url: props.templatevar.block_3_link_url,
      colour: props.templatevar.block_3_colour,
      image: props.templatevar.block_3_image,
    },
  ]
})
</script>
