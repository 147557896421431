<template>
  <div class="mx-auto rounded-lg bg-gray-50 dark:bg-gray-800">
    <div class="relative">
      <div class="mx-auto flex max-w-7xl">
        <div
          class="relative z-10 w-full max-w-xl rounded-lg bg-gray-50 pb-2 dark:bg-gray-800 md:rounded-r-none"
        >
          <svg
            class="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 text-gray-100 dark:text-gray-700 md:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <main class="relative mx-auto px-4 py-6 sm:px-6 sm:py-8 lg:px-8 lg:py-10">
            <div class="text-left">
              <h1
                class="mb-3 space-x-2 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl"
              >
                <span>Find agents in your area</span>
                <span class="inline-block text-brand-600 dark:text-brand-500">within minutes</span>
              </h1>
              <cgn-button color-brand url="/findAgent" size-large>
                Start Now
              </cgn-button>
            </div>
          </main>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 hidden w-1/2 overflow-hidden rounded-lg md:block">
        <cgn-lazy-image image-hash="VyK1" image-aspect="4x3" image-width="600" />
      </div>
    </div>
  </div>
</template>
