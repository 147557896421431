<template>
  <div class="p-6 sm:p-12 space-y-2 text-gray-800 mx-auto max-w-none lg:row-start-1 lg:col-start-1 text-center" :style="{ 'background-color': templatevar.background_colour }">
    <h1 class="text-2xl font-semibold">
      {{ templatevar.heading }}
    </h1>
    <h2 class="text-xl">
      {{ templatevar.subheading }}
    </h2>
    <div class="prose max-w-5xl mx-auto" v-html="templatevar.block_content" />
  </div>
</template>

<script lang="ts">
class Templatevars {
  heading?: string
  subheading?: string
  block_content?: string
  background_colour?: string
  button_name?: string
  anchor_name?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
