<template>
  <div
    class="relative bg-gradient-to-br from-gray-100 to-gray-300 dark:to-black dark:from-gray-700"
  >
    <cgn-breadcrumb :links="breadcrumbs" />
    <div class="sm:flex pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 gap-10">
      <div class="relative max-w-7xl mx-auto">
        <div class="text-center">
          <h1
            class="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-gray-100 sm:text-4xl"
          >
            From the blog
            <div class="text-2xl sm:text-3xl font-bold text-brand-500 dark:text-brand-400">
              {{ groupDetails.name }}
            </div>
          </h1>
          <div class="prose prose-brand dark:prose-dark" v-html="groupDetails.content" />
        </div>
        <div class="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-3 md:max-w-none">
          <div
            v-for="post, index in posts"
            :key="index"
            class="flex flex-col rounded-lg shadow-lg overflow-hidden"
          >
            <div class="flex-shrink-0">
              <router-link :to="`/article/${post.slug}`">
                <cgn-lazy-image
                  class="w-full"
                  :image="post.image"
                />
              </router-link>
            </div>
            <div class="flex-1 bg-white dark:bg-gray-800 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <router-link :to="`/article/${post.slug}`" class="block mt-2">
                  <p class="text-xl font-semibold text-gray-900 dark:text-gray-100">
                    {{ post.name }}
                  </p>
                  <div class="mt-3 text-base text-gray-500 dark:text-gray-200" v-html="post.blurb" />
                </router-link>
              </div>
              <div v-if="false" class="mt-6 flex items-center">
                <div class="ml-3">
                  <p class="text-sm font-medium text-gray-900 dark:text-gray-100">
                    {{ post.author }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-grow justify-center py-5">
          <cgn-pagination
            :current-page="props.page"
            :page-count="num_pages"
            :group="props.group"
            url-prefix="blog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { CognitoArticle } from '~cognito/models/Cognito/Article'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  group: {
    type: String,
  },
  page: {
    type: String,
  },
})

const posts = ref<CognitoArticle[]>([])
const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const num_pages = ref(0)

const breadcrumbs = ref([{ name: 'Articles', url: '/blog' }])

async function loadArticles() {
  posts.value = []
  num_pages.value = 1
  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = ''
  }
  groupDetails.value = new CognitoGroup()
  breadcrumbs.value = [{ name: 'Articles', url: '/blog' }]
  const articles = await new CognitoArticle().find_many({
    image_aspect: '4x3',
    image_width: 600,
    page_size: 9,
    page,
    group,
  })
  posts.value = articles.data
  num_pages.value = articles.num_pages

  if (group) {
    // Need the group to get heading etc
    const groupData = await new CognitoGroup().find_one({
      namespace: 'Cognito',
      model: 'Article',
      group,
    })
    groupDetails.value = groupData
    breadcrumbs.value.push({ name: groupData.name, url: group })
  }
}
watch(() => props, () => {
  loadArticles()
}, {
  deep: true,
})
onMounted(() => {
  loadArticles()
})
onServerPrefetch(async () => {
  await loadArticles()
})
</script>
