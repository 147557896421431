<template>
  <div class="p-6 sm:p-12 space-y-2 text-gray-900 dark:text-gray-200 mx-auto max-w-5xl">
    <div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-2xl mt-3 mb-4">
            Property Management
          </h2>
          <table class="w-full">
            <tr>
              <td>
                Rental Amount
              </td>
              <td class="text-danger">
                Fee (Inc GST)
              </td>
            </tr>
            <tr v-for="price in rental_prices" :key="price.price_from" class="odd:bg-slate-300 dark:odd:bg-slate-700">
              <td>
                {{ price.price_from }}
                -
                {{ price.price_to }}
              </td>
              <td class="text-danger">
                {{ price.fee }}
              </td>
            </tr>
          </table>
          <h2 class="text-2xl mt-3 mb-4">
            Property Sale
          </h2>
          <table class="w-full">
            <tr>
              <td>
                Sale Amount
              </td>
              <td class="text-danger">
                Fee (Inc GST)
              </td>
            </tr>
            <tr v-for="price in sale_prices" :key="price.price_from" class="odd:bg-slate-300 dark:odd:bg-slate-700">
              <td>
                {{ price.price_from }}
                -
                {{ price.price_to }}
              </td>
              <td class="text-danger">
                {{ price.fee }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
</script>

<script setup lang="ts">
import { $axios } from '~cognito/plugins/axios'
class Prices {
  price_from?: string
  price_to?: string
  fee?: string
}

const rental_prices = ref<Prices[]>([])
const sale_prices = ref<Prices[]>([])

const loadPrices = async () => {
  const prices = await $axios.get('/api/v1/real/lead/serviceFeePrices')
  rental_prices.value = prices.data.rental_prices
  sale_prices.value = prices.data.sale_prices
}
onMounted(() => {
  loadPrices()
})
onServerPrefetch(async () => {
  await loadPrices()
})
</script>
