import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://real.${config.devbase}`
config.prodURL = 'https://cms.real-connect.com.au'
config.siteURL = 'https://real-connect.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '4x3'
config.isEcommerce = false
config.sentryDsn = 'https://5b5be5c11a554c3e977e5923cd1dc274@glitchtip.logger.jm1.au/13'

export { config }
