<template>
  <div
    class="p-6 sm:p-12 space-y-2 text-gray-700 dark:text-gray-200 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1"
  >
    <h1 class="max-w-5xl mx-auto text-2xl font-semibold">
      {{ templatevar.headline }}
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 max-w-5xl mx-auto gap-4">
      <div v-for="block in blocks" :key="block.text" class="bg-white dark:bg-slate-800 rounded-md overflow-hidden">
        <div class="relative overflow-hidden">
          <div class="absolute inset-0 bg-slate-900 opacity-40" />
          <div class="bg-brand-500 text-white rounded-br-md absolute left-0 top-0 px-4 py-2">
            {{ block.badge }}
          </div>
          <div class="absolute inset-0 flex justify-center items-center">
            <div class="text-white text-xl p-2 text-center" v-html="block.text" />
          </div>
          <cgn-lazy-image image-aspect="Compare" image-width="400" :image-id="block.image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
class Templatevars {
  headline?: string
  image_1?: string
  badge_1?: string
  text_1?: string
  image_2?: string
  badge_2?: string
  text_2?: string
  image_3?: string
  badge_3?: string
  text_3?: string
  image_4?: string
  badge_4?: string
  text_4?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const blocks = computed(() => {
  return [
    {
      image: props.templatevar.image_1,
      badge: props.templatevar.badge_1,
      text: props.templatevar.text_1,
    },
    {
      image: props.templatevar.image_2,
      badge: props.templatevar.badge_2,
      text: props.templatevar.text_2,
    },
    {
      image: props.templatevar.image_3,
      badge: props.templatevar.badge_3,
      text: props.templatevar.text_3,
    },
    {
      image: props.templatevar.image_4,
      badge: props.templatevar.badge_4,
      text: props.templatevar.text_4,
    },
  ]
})
</script>
