<template>
  <div class="p-6 sm:p-12 space-y-2 text-gray-700 dark:text-gray-200 mx-auto lg:row-start-1 lg:col-start-1">
    <h1 class="text-2xl font-semibold max-w-5xl mx-auto">
      {{ templatevar.headline }}
    </h1>
    <div class="max-w-5xl mx-auto">
      <div role="list" class="overflow-hidden">
        <div v-for="block in blocks" :key="block.text" class="relative pb-10">
          <div class="absolute top-8 left-8 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
          <div class="relative flex items-start">
            <cgn-lazy-image image-aspect="1x1" image-width="400" :image-id="block.image" class="h-16 w-16 rounded-full border-2 border-gray-300 bg-white" />
            <span class="ml-4 flex flex-col">
              <div class="text-lg font-semibold">
                {{ block.badge }}
              </div>
              <div>
                {{ block.text }}
              </div>
              <div>
                {{ block.info }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
class Templatevars {
  headline?: string
  image_1?: string
  badge_1?: string
  text_1?: string
  info_1?: string
  image_2?: string
  badge_2?: string
  text_2?: string
  info_2?: string
  image_3?: string
  badge_3?: string
  text_3?: string
  info_3?: string
  image_4?: string
  badge_4?: string
  text_4?: string
  info_4?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const blocks = computed(() => {
  return [
    {
      image: props.templatevar.image_1,
      badge: props.templatevar.badge_1,
      text: props.templatevar.text_1,
      info: props.templatevar.info_1,
    },
    {
      image: props.templatevar.image_2,
      badge: props.templatevar.badge_2,
      text: props.templatevar.text_2,
      info: props.templatevar.info_2,
    },
    {
      image: props.templatevar.image_3,
      badge: props.templatevar.badge_3,
      text: props.templatevar.text_3,
      info: props.templatevar.info_3,
    },
    {
      image: props.templatevar.image_4,
      badge: props.templatevar.badge_4,
      text: props.templatevar.text_4,
      info: props.templatevar.info_4,
    },
  ]
})
</script>
