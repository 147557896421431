<template>
  <div class="p-6 sm:p-12 space-y-2 prose prose-brand dark:prose-dark text-gray-700 dark:text-gray-200 mx-auto max-w-5xl mx-auto lg:row-start-1 lg:col-start-1">
    <h1 class="text-2xl font-semibold">
      {{ templatevar.heading }}
    </h1>
    <div class="prose dark:prose-invert max-w-none" v-html="templatevar.pagecontent" />
    <div class="divide-y-2 divide-gray-300 dark:divide-gray-600">
      <div v-for="faq in faqs" :key="faq.id" class="py-4 cursor-pointer group" @click="select_faq(faq.id)">
        <div class="flex flex-row justify-between group-hover:text-brand-600 dark:group-hover:text-brand-500">
          <div class="text-xl select-none">
            {{ faq.name }}
          </div>
          <div>
            <i-heroicons-outline:x-circle v-if="selected_faq == faq.id" class="w-6 h-6" />
            <i-heroicons-outline:plus-circle v-else class="w-6 h-6" />
          </div>
        </div>
        <div v-if="selected_faq == faq.id" class="prose dark:prose-invert p-2" v-html="faq.content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoFaq } from '~cognito/models/Cognito/Faq'
class Templatevars {
  heading?: string
  pagecontent?: string
  group?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const faqs = ref<CognitoFaq[]>([])

const selected_faq = ref()
const select_faq = (faq_id) => {
  if (selected_faq.value == faq_id) {
    selected_faq.value = null
    return
  }
  selected_faq.value = faq_id
}

const loadFaqs = async () => {
  const data = await new CognitoFaq().find_many({
    group: props.templatevar.group,
  })
  faqs.value = data.data
}
onMounted(() => {
  loadFaqs()
})
onServerPrefetch(async () => {
  await loadFaqs()
})
</script>
