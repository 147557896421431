<template>
  <div class="space-y-2 text-white mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
    <cgn-parallax :image-id="templatevar.image" class="p-8 text-center h-[500px] flex flex-col justify-center items-center">
      <h1 class="text-2xl font-semibold">
        {{ templatevar.heading }}
      </h1>
      <h2 v-if="templatevar.sub_heading" class="text-xl font-semibold">
        {{ templatevar.sub_heading }}
      </h2>
      <div v-if="templatevar.text_content" class="prose-invert max-w-none pb-2" v-html="templatevar.text_content" />

      <div v-if="templatevar.btn_link_1" class="flex flex-col md:flex-row md:gap-2 justify-center">
        <ss-button v-if="templatevar.btn_link_1" :url="templatevar.btn_link_1">
          {{ templatevar.btn_text_1 }}
        </ss-button>
        <ss-button v-if="templatevar.btn_link_2" :url="templatevar.btn_link_2">
          {{ templatevar.btn_text_2 }}
        </ss-button>
        <ss-button v-if="templatevar.btn_link_3" :url="templatevar.btn_link_3">
          {{ templatevar.btn_text_3 }}
        </ss-button>
        <ss-button v-if="templatevar.btn_link_4" :url="templatevar.btn_link_4">
          {{ templatevar.btn_text_4 }}
        </ss-button>
      </div>
      <div v-else class="flex flex-col md:flex-row md:gap-2 justify-center">
        <ss-button url="/selling-my-property">
          <i-heroicons-solid:tag class="inline" /> Sell my property
        </ss-button>
        <ss-button url="/sale-appraisal">
          <i-heroicons-solid:book-open class="inline" /> Sale appraisal
        </ss-button>
        <ss-button url="/rent-my-property">
          <i-heroicons-solid:key class="inline" /> Rent my property
        </ss-button>
        <ss-button url="/rent-appraisal">
          <i-heroicons-solid:book-open class="inline" /> Rent appraisal
        </ss-button>
      </div>
    </cgn-parallax>
  </div>
</template>

<script lang="ts">
class Templatevars {
  image?: number
  heading?: string
  sub_heading?: string
  text_content?: string
  btn_link_1?: string
  btn_text_1?: string
  btn_link_2?: string
  btn_text_2?: string
  btn_link_3?: string
  btn_text_3?: string
  btn_link_4?: string
  btn_text_4?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
