<template>
  <div class="sm:pw-12 mx-auto max-w-xl py-6">
    <div class="prose-brand prose text-gray-500 dark:prose-dark dark:text-gray-200">
      <h1 class="text-2xl font-semibold">
        <span v-if="templatevar.lead_type == 2">Sell My Property</span>
        <span v-if="templatevar.lead_type == 3">Rent My Property</span>
        <span v-if="templatevar.lead_type == 5">Sale Appraisal</span>
        <span v-if="templatevar.lead_type == 6">Rent Appraisal</span>
      </h1>
      <p>
        We have agents for your property, simply tell us where your property is located and provide some contact details, and within a few minutes we'll email you your unique link to view matching agents for you!
      </p>
    </div>
    <cgn-button color-brand url="/findAgent">
      Find Agents Now
    </cgn-button>
  </div>
</template>

<script lang="ts">
class Templatevars {
  lead_type?: number
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
